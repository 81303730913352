.content {
  text-align: center;
  margin: 40px 0 100px;
}

.picture-wrapper {
  max-width: 800px;
  width: 100%;
  margin: 0 auto 60px;
  position: relative;
}

.star {
  display: inline-flex;
  position: absolute;
  z-index: -1;
}

.alien {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.plate {
  z-index: 2;
  max-width: 390px;
  svg {
    width: 100%;
    height: auto;
  }
}

.triangle {
  transform: translateY(-20px);
  z-index: 1;
  padding: 0 15px;
  max-width: 419px;
  width: 100%;

  svg {
    width: 100%;
    height: auto;
  }
}

.ghost {
  display: flex;
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translateX(-50%);
  z-index: 3;
  animation: ghost 3s infinite ease-in-out;
}

.subhead {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #737373;
}

.header {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: clamp(40px, 10vw, 58px);
  line-height: clamp(57px, 10vw, 80px);
  color: #008060;
  margin-bottom: 2%;
}

@keyframes ghost {
  0% {
    transform: translateY(-40%) translateX(-50%) rotate(15deg);
  }
  50% {
    transform: translateY(20%) translateX(-50%) rotate(-30deg);
  }
  100% {
    transform: translateY(-40%) translateX(-50%) rotate(15deg);
  }
}